<template>
  <v-card>
    <v-tabs v-model="tab">
      <v-tab
        v-for="(item, idx) in tabs"
        :key="idx"
        :to="{name: item.name}"
        class="d-flex justify-start"
      >
        <v-icon left>
          {{ item.icon }}
        </v-icon>
        {{ item.title }}
      </v-tab>
      <v-tabs-items touchless v-model="tab">
        <v-fade-transition mode="out-in">
          <router-view />
        </v-fade-transition>
      </v-tabs-items>
    </v-tabs>
  </v-card>
</template>

<script>
import cloneDeep from 'lodash.clonedeep'

export default {
  name: 'AuctionComponent',
  data: () => ({
    windowSize: {},
    tab: 0,
    tabs: [
      { name: 'Calendar', title: 'Календар', icon: 'mdi-calendar' },
      { name: 'Tree', title: 'Дерево', icon: 'mdi-file-tree' }
    ]
  }),
  methods: {
    onResize () {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight }
    }
  },
  mounted () {
    this.filteredItems = cloneDeep(this.items)
    setTimeout(() => {
      const areas = document.querySelectorAll('.area')
      areas.forEach((val, idx) => {
        if (!(idx % 2)) {
          val.parentElement.parentElement.parentElement.style.background =
            '#e1f8eb'
        }
      })
    }, 200)
  }
}
</script>
